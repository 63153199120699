import { Timestamp } from 'firebase/firestore';
import {
  eOfferTypes,
  ePoolAbsenceCategory,
  ePoolAbsenceFlow,
  ePoolItemRequestStatus,
  ePoolItemStatus,
  eShiftType,
} from '../../Enums';
import { iDepartmentRef } from '../Departments';
import { iQualification, iQualificationRef } from '../Qualifications';
import { iEmoji, iShift, iShiftRef } from '../Shifts';
import { iUserRef } from '../Users';
import { iSentEmailNotification } from '../Modules/EmailNotifications';

// Needs to hold translation reference instead of name
export interface iPoolAbsenceType {
  category: ePoolAbsenceCategory;
  createdAt: Timestamp;
  id: string;
  name: string;
  flow?: ePoolAbsenceFlow;
  position: number;
  updatedAt: Timestamp;
}

export interface iPoolAbsence extends Pick<iPoolAbsenceType, 'category' | 'flow'> {
  id: string;
  userId: string;
  type: iPoolAbsenceType['name'];
  grantedByUserId?: string;
  when: Timestamp;
  whenString: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  createdBy: string;
  updatedBy: string;
}

export const vacationPoolAbsenceCategories = [ePoolAbsenceCategory.PaidLeave] as const;

export const nonVacationPoolAbsenceCategories = [
  ePoolAbsenceCategory.Off,
  ePoolAbsenceCategory.Other,
  ePoolAbsenceCategory.Sickness,
] as const;

export const poolAbsenceCategoryLabelByCategory = {
  [ePoolAbsenceCategory.Off]: 'absence-category.off',
  [ePoolAbsenceCategory.PaidLeave]: 'absence-category.paid-leave',
  [ePoolAbsenceCategory.Sickness]: 'absence-category.sickness',
  [ePoolAbsenceCategory.Other]: 'absence-category.other',
} as const;

export const poolAbsenceCategories = Object.values(ePoolAbsenceCategory);

export const isPoolAbsenceCategory = (poolAbsenceCategory: unknown): poolAbsenceCategory is ePoolAbsenceCategory => {
  return poolAbsenceCategories.includes(poolAbsenceCategory as ePoolAbsenceCategory);
};

export const isPoolAbsenceType = (poolAbsenceType: unknown): poolAbsenceType is iPoolAbsenceType => {
  return (
    poolAbsenceType !== null &&
    typeof poolAbsenceType === 'object' &&
    'name' in poolAbsenceType &&
    'category' in poolAbsenceType &&
    isPoolAbsenceCategory(poolAbsenceType.category)
  );
};

export const isPoolAbsence = (poolAbsence: unknown): poolAbsence is iPoolAbsence => {
  return (
    poolAbsence !== null &&
    typeof poolAbsence === 'object' &&
    'type' in poolAbsence &&
    'category' in poolAbsence &&
    poolAbsenceCategories.includes(poolAbsence.category as (typeof poolAbsenceCategories)[number])
  );
};

export const isPaidLeavePoolAbsence = (
  poolAbsence: iPoolAbsence | undefined,
): poolAbsence is iPoolAbsence & { category: ePoolAbsenceCategory.PaidLeave } => {
  if (!isPoolAbsence(poolAbsence)) {
    return false;
  }
  return ePoolAbsenceCategory.PaidLeave === poolAbsence.category;
};

export const isNonPaidLeavePoolAbsence = (
  poolAbsence: iPoolAbsence | undefined,
): poolAbsence is iPoolAbsence & { category: Exclude<iPoolAbsence['category'], ePoolAbsenceCategory.PaidLeave> } => {
  if (!isPoolAbsence(poolAbsence)) {
    return false;
  }
  return ePoolAbsenceCategory.PaidLeave !== poolAbsence.category;
};

export type poolItemStatusTypes = 'option' | 'accepted' | 'offered' | 'rejected' | 'unavailable' | 'vacation';

export interface iPoolItem {
  id: string;
  userId: string;
  displayName: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  offeredAt: Timestamp;
  offeredBy: iUserRef;
  acceptedAt: Timestamp;
  rejectedAt: Timestamp;
  when: Timestamp;
  whenString: string;
  atDepartment: iDepartmentRef;
  shift: iShiftRef | null;
  poolShift: iPoolShiftRef | null;
  createdBy: string;
  updatedBy: string;
  status: ePoolItemStatus;
  type: eOfferTypes.PoolItem;
  qualifications: iQualificationRef[];
  poolItemRequestId: string | undefined;
  sentEmailNotifications?: iSentEmailNotification[];
}

export interface iPoolItemRequest {
  id: string;
  whenString: string;
  requestedUserIds: string[];
  requestedUsers: iUserRef[];
  shiftId: string;
  departmentId: string;
  departmentName: string;
  shiftName: string;
  shiftFromTill: string;
  shiftIcon: string;
  shiftShort: string;
  qualifications: iQualification[];
  createdById: string;
  createdByDisplayName: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  status: ePoolItemRequestStatus;
  type: eOfferTypes.PoolItemRequest;
  note: string;
  assignedName: string;
  assignedAt: Timestamp;
  assignedBy: string;
  assignedPoolItemId: string;
  assignedToId: string;
  assignedToName: string;
  poolItems: iPoolItem[];
}

/**
 * pool user details
 */
export interface iPoolUserDetails {
  createdAt: Timestamp;
  updatedAt: Timestamp;
  vacationDaysTaken: number;
  vacationDays: number;
  /**
   * The number of hours the user has to schedule per week
   * @default 40
   */
  contractHours: number;
  /**
   * The length of a workday in hours
   * @default 8
   */
  workdayLength: number;
  /**
   * The break time in hours
   * @default 0.5
   */
  workdayBreakLength: number;
  /**
   * The number of days the user hast to have filled out in advance
   * @default 28
   */
  leadTime: number; //Days
}

export interface iPoolShift extends Omit<iShift, 'type'> {
  type: eShiftType.PoolShift;
}

export interface iPoolShiftRef {
  id: string;
  name: string;
  short?: string;
  icon?: iEmoji | null;
  fromTill?: string;
  type: eShiftType.PoolShift;
}
