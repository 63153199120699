import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { iShift } from 'datamodel';

const initialState: iShift[] = [];

export const shiftsSlice = createSlice({
  name: 'shifts',
  initialState,
  reducers: {
    setShiftsValue: (state, action: PayloadAction<iShift[]>) =>
      action.payload.sort((a, b) => a.fromTill.localeCompare(b.fromTill)),
  },
});

// Action creators are generated for each case reducer function
export const { setShiftsValue } = shiftsSlice.actions;

export default shiftsSlice.reducer;
