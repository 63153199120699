import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { iFlexiRequest } from 'datamodel';

const initialState: iFlexiRequest[] = [];

export const flexiRequestsSlice = createSlice({
  name: 'flexiRequests',
  initialState,
  reducers: {
    setFlexiRequestValue: (state, action: PayloadAction<iFlexiRequest[]>) => action.payload,
  },
});

export const { setFlexiRequestValue } = flexiRequestsSlice.actions;

export default flexiRequestsSlice.reducer;
