import { CssBaseline, StyledEngineProvider, Theme, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { iUserRecord } from 'datamodel';
import { auth } from 'localFirebase';
import moment from 'moment';
import { useAppSelector } from 'rdx/hooks';
import { setUserRecordValue } from 'rdx/slices/userRecord';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { getTheme } from 'themes';
import { crewLinQTheme } from 'themes/branches/crewlinq';
import Cookies from 'universal-cookie';
import i18n from 'utils/i18n';
import { getBrowserLocales } from 'utils/toolkit';

const Privacy = lazy(() => import('./views/Privacy'));
const QRCode = lazy(() => import('./views/MiniSites/QRCode'));
const Calculator = lazy(() => import('./views/MiniSites/Calculator'));
const Store = lazy(() => import('./views/Store'));
const AppFrame = lazy(() => import('./views/AppFrame'));

function App() {
  const languageCode = getBrowserLocales({ languageCodeOnly: true });
  const dispatch = useDispatch();
  const setLanguage = (v: Partial<iUserRecord>) => dispatch(setUserRecordValue(v));
  const { theme: selectedTheme, loggedIn } = useAppSelector((state) => state.appState);
  const userRecord = useAppSelector((state) => state.userRecord);
  const cookies = new Cookies();
  const lng = cookies.get('language');
  const [currentTheme, setCurrentTheme] = useState<Theme>(crewLinQTheme('de'));
  // sets dom's language based on user's preferred language
  const setDomLanguage = (newLang: string) => {
    document.documentElement.lang = newLang;
  };

  useEffect(() => {
    if (lng) {
      setLanguage({ language: lng });
      i18n.changeLanguage(lng);
      moment.locale(lng);
      if (lng === 'de') {
        auth.languageCode = 'de-DE';
      } else {
        auth.languageCode = 'en-US';
      }
    } else {
      i18n.changeLanguage(languageCode ? languageCode[0] : 'de');
      moment.locale(getBrowserLocales(languageCode ? languageCode[0] : 'de'));
      setLanguage({ language: 'de' });
    }
    cookies.set(lng === 'de' ? 'de' : 'en', { path: '/' });
    setCurrentTheme(getTheme(lng === 'de' ? 'de' : 'en').theme);
    setDomLanguage(i18n.language);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loggedIn) return;
    const lang = userRecord.language === 'de' ? 'de' : 'en';
    setLanguage({ language: lang });
    i18n.changeLanguage(lang);
    moment.locale(lang);
    if (lang === 'de') {
      auth.languageCode = 'de-DE';
    } else {
      auth.languageCode = 'en-US';
    }
    cookies.set('language', lang, { path: '/' });
    setCurrentTheme(getTheme(lng === 'de' ? 'de' : 'en').theme);
    setDomLanguage(i18n.language);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRecord.language, selectedTheme, loggedIn]);

  return (
    <div className="App">
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={currentTheme}>
            <CssBaseline />
            <Routes>
              <Route
                path="/qr"
                element={
                  <Suspense fallback={<>...</>}>
                    <QRCode />
                  </Suspense>
                }
              />

              <Route
                path="/privacy"
                element={
                  <Suspense fallback={<>...</>}>
                    <Privacy />
                  </Suspense>
                }
              />
              <Route
                path="/store"
                element={
                  <Suspense fallback={<>...</>}>
                    <Store />
                  </Suspense>
                }
              />
              <Route
                path="/calculator"
                element={
                  <Suspense fallback={<>...</>}>
                    <Calculator />
                  </Suspense>
                }
              />
              <Route
                path="/*"
                element={
                  <Suspense fallback={<>...</>}>
                    <AppFrame />
                  </Suspense>
                }
              />
            </Routes>
          </ThemeProvider>
        </StyledEngineProvider>
      </LocalizationProvider>
    </div>
  );
}
export default withTranslation()(App);
