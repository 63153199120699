/* eslint-disable max-lines */
import { checkCustomerId, checkTenantId, checkUserId } from 'database';
import {
  AllFieldValues,
  eLeasingEmployeeOfferStatus,
  eUserCapabilities,
  iContactInformation,
  iDepartment,
  iLeasingQualificationBase,
  iOffer,
  iOfferAccepted,
  iPoolAbsence,
  iPoolAbsenceType,
  iPoolItem,
  iPoolItemRequest,
  iPoolShift,
  iQualification,
  iShift,
  iUser,
} from 'datamodel';
import {
  addDoc,
  arrayUnion,
  collection,
  CollectionReference,
  deleteDoc,
  deleteField,
  doc,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  getDoc,
  getDocs,
  orderBy,
  Query,
  query,
  QuerySnapshot,
  setDoc,
  Timestamp,
  updateDoc,
  where,
} from 'firebase/firestore';
import { db } from 'localFirebase';
import moment from 'moment';
import { getAUserRef, getUserRef } from './user';

const getData = async (docRef: DocumentReference<DocumentData>): Promise<DocumentData | undefined> => {
  const document = await getDoc(docRef);
  if (!document || !document.exists) return undefined;
  return document.data();
};

export const getOffersRef = (): CollectionReference<DocumentData> | false => {
  const customerId = checkCustomerId();
  const tenantId = checkTenantId();
  if (!tenantId || !customerId) return false;
  const offer = collection(db, 'customers', customerId, 'houses', tenantId, 'offers');
  return offer;
};

export const getPoolAbsencesRef = () => {
  const customerId = checkCustomerId();
  const tenantId = checkTenantId();
  if (!tenantId || !customerId) return false;
  const poolAbsencesReference = collection(db, 'customers', customerId, 'houses', tenantId, 'poolAbsences');
  return poolAbsencesReference;
};

export const addPoolAbsence = (poolAbsence: Omit<iPoolAbsence, 'id'>) => {
  const poolAbsencesReference = getPoolAbsencesRef();
  if (!poolAbsencesReference) return;
  return addDoc(poolAbsencesReference, poolAbsence);
};

export const updatePoolAbsence = (
  id: string,
  poolAbsence: Omit<iPoolAbsence, 'id' | 'createdAt' | 'createdBy' | 'userId' | 'whenString' | 'when'>,
) => {
  const poolAbsencesReference = getPoolAbsencesRef();
  if (!poolAbsencesReference) return;
  return updateDoc(doc(poolAbsencesReference, id), poolAbsence);
};

export const removePoolAbsence = (id: string) => {
  const poolAbsencesReference = getPoolAbsencesRef();
  if (!poolAbsencesReference) return;
  return deleteDoc(doc(poolAbsencesReference, id));
};

export const getPoolAbsenceTypesRef = () => {
  const customerId = checkCustomerId();
  const tenantId = checkTenantId();
  if (!tenantId || !customerId) return false;
  const poolAbsenceTypesReference = collection(db, 'customers', customerId, 'houses', tenantId, 'poolAbsenceTypes');
  return poolAbsenceTypesReference;
};

export const addPoolAbsenceType = (poolAbsenceType: Omit<iPoolAbsenceType, 'id'>) => {
  const poolAbsenceTypesReference = getPoolAbsenceTypesRef();
  if (!poolAbsenceTypesReference) return;
  return addDoc(poolAbsenceTypesReference, poolAbsenceType);
};

export const updatePoolAbsenceType = (id: string, poolAbsenceType: Omit<iPoolAbsenceType, 'id' | 'createdAt'>) => {
  const poolAbsenceTypesReference = getPoolAbsenceTypesRef();
  if (!poolAbsenceTypesReference) return;
  return updateDoc(doc(poolAbsenceTypesReference, id), poolAbsenceType);
};

export const removePoolAbsenceType = (id: string) => {
  const poolAbsenceTypesReference = getPoolAbsenceTypesRef();
  if (!poolAbsenceTypesReference) return;
  return deleteDoc(doc(poolAbsenceTypesReference, id));
};

export const getPoolItemsRef = (): CollectionReference<DocumentData> | false => {
  const customerId = checkCustomerId();
  const tenantId = checkTenantId();
  if (!tenantId || !customerId) return false;
  const poolItems = collection(db, 'customers', customerId, 'houses', tenantId, 'poolItems');
  return poolItems;
};

export const getPoolItemRequestsRef = (): CollectionReference<DocumentData> | false => {
  const customerId = checkCustomerId();
  const tenantId = checkTenantId();
  if (!customerId) return false;
  if (!tenantId) return false;
  const poolItemRequests = collection(db, 'customers', customerId, 'houses', tenantId, 'poolItemRequests');
  return poolItemRequests;
};

export const getPoolItemRequestRef = (id: string): DocumentReference<DocumentData> | false => {
  const customerId = checkCustomerId();
  const tenantId = checkTenantId();
  if (!customerId) return false;
  if (!tenantId) return false;
  const poolItemRequest = doc(db, 'customers', customerId, 'houses', tenantId, 'poolItemRequests', id);
  return poolItemRequest;
};

export const updatePoolItemRequest = async (id: string, value: Partial<iPoolItemRequest>): Promise<void> => {
  const docRef = getPoolItemRequestRef(id);
  if (!docRef) return;
  const res = await updateDoc(docRef, value);
  return res;
};

export const getQualificationsRef = (): CollectionReference<DocumentData> | false => {
  const customerId = checkCustomerId();
  const tenantId = checkTenantId();
  if (!tenantId || !customerId) return false;
  const qualifications = collection(db, 'customers', customerId, 'houses', tenantId, 'qualifications');
  return qualifications;
};

export const getQualificationRef = (id: string): DocumentReference<DocumentData> | false => {
  const customerId = checkCustomerId();
  const tenantId = checkTenantId();
  if (!tenantId || !customerId) return false;
  const qualification = doc(db, 'customers', customerId, 'houses', tenantId, 'qualifications', id);
  return qualification;
};

export const getDepartmentsRef = (): CollectionReference<DocumentData> | false => {
  const customerId = checkCustomerId();
  const tenantId = checkTenantId();
  if (!tenantId || !customerId) return false;
  const departments = collection(db, 'customers', customerId, 'houses', tenantId, 'departments');
  return departments;
};

export const getDepartmentRef = (id: string): DocumentReference<DocumentData> | false => {
  const customerId = checkCustomerId();
  const tenantId = checkTenantId();
  if (!tenantId || !customerId) return false;
  const department = doc(db, 'customers', customerId, 'houses', tenantId, 'departments', id);
  return department;
};

export const getTextsRef = (): CollectionReference<DocumentData> | false => {
  const customerId = checkCustomerId();
  const tenantId = checkTenantId();
  if (!tenantId || !customerId) return false;
  const texts = collection(db, 'customers', customerId, 'houses', tenantId, 'texts');
  return texts;
};

export const getTextRef = (id: string): DocumentReference<DocumentData> | false => {
  const customerId = checkCustomerId();
  const tenantId = checkTenantId();
  if (!tenantId || !customerId) return false;
  const texts = doc(db, 'customers', customerId, 'houses', tenantId, 'texts', id);
  return texts;
};

export const getShiftsRef = (): CollectionReference<DocumentData> | false => {
  const customerId = checkCustomerId();
  const tenantId = checkTenantId();
  if (!tenantId || !customerId) return false;
  const shifts = collection(db, 'customers', customerId, 'houses', tenantId, 'shifts');
  return shifts;
};

export const getPoolShiftsRef = (): CollectionReference<DocumentData> | false => {
  const customerId = checkCustomerId();
  const tenantId = checkTenantId();
  if (!tenantId || !customerId) return false;
  const shifts = collection(db, 'customers', customerId, 'houses', tenantId, 'poolShifts');
  return shifts;
};

export const getUsersRef = (): CollectionReference<DocumentData> | false => {
  const customerId = checkCustomerId();
  const tenantId = checkTenantId();
  if (!tenantId || !customerId) return false;
  const users = collection(db, 'customers', customerId, 'houses', tenantId, 'users');
  return users;
};

export const getOfferRef = (offerId: string): DocumentReference<DocumentData> | false => {
  const customerId = checkCustomerId();
  const tenantId = checkTenantId();
  if (!tenantId || !customerId) return false;
  const offer = doc(db, 'customers', customerId, 'houses', tenantId, 'offers', offerId);
  return offer;
};

export const getOfferRefByTenantId = (offerId: string, tenantId: string): DocumentReference<DocumentData> | false => {
  const customerId = checkCustomerId();
  if (!tenantId || !customerId) return false;
  const offer = doc(db, 'customers', customerId, 'houses', tenantId, 'offers', offerId);
  return offer;
};

const getShiftRef = (id: string) => {
  const customerId = checkCustomerId();
  const tenantId = checkTenantId();
  if (!tenantId || !customerId) return false;
  const shift = doc(db, 'customers', customerId, 'houses', tenantId, 'shifts', id);
  return shift;
};

export const removeOfferAssignment = async (offerId: string): Promise<void> => {
  const offerRef = getOfferRef(offerId);
  if (!offerRef) return;
  const res = await updateDoc(offerRef, {
    assignedAt: deleteField(),
    assignedBy: deleteField(),
    assigned: deleteField(),
    isAssigned: false,
  });
  return res;
};

const getPoolShiftRef = (id: string) => {
  const customerId = checkCustomerId();
  const tenantId = checkTenantId();
  if (!tenantId || !customerId) return false;
  const shift = doc(db, 'customers', customerId, 'houses', tenantId, 'poolShifts', id);
  return shift;
};

export const updateOfferById = async (offerId: string, value: Partial<iOffer>): Promise<void> => {
  const offerRef = getOfferRef(offerId);
  if (!offerRef) return;
  console.time('updateOfferById');
  const res = await updateDoc(offerRef, value);
  console.timeEnd('updateOfferById');
  return res;
};

export const updateOfferAcceptedMapItem = async (
  offerId: string,
  acceptedId: string,
  acceptedValue: iOfferAccepted,
): Promise<void> => {
  const offerRef = getOfferRef(offerId);
  if (!offerRef) return;
  const path = `accepted.${acceptedId}`;
  const res = await updateDoc(offerRef, { [path]: acceptedValue });
  return res;
};

export const updateUser = async (value: Partial<iUser>): Promise<void> => {
  const userRef = getUserRef();
  if (!userRef) return;
  const res = await updateDoc(userRef, value);
  return res;
};

export const updateUserDepartment = async (departmentIds: string[]): Promise<void> => {
  const userRef = getUserRef();
  if (!userRef) return;
  const res = await updateDoc(userRef, { departmentIds, department: deleteField() });
  return res;
};

export const updateAUserDepartment = async (uid: string, departmentIds: string[]): Promise<void> => {
  const userRef = getAUserRef(uid);
  if (!userRef) return;
  const res = await updateDoc(userRef, { departmentIds, department: deleteField() });
  return res;
};

export const updateAUser = async (uid: string, value: Partial<iUser>): Promise<void> => {
  const aUserRef = getAUserRef(uid);
  if (!aUserRef) return;
  const res = await updateDoc(aUserRef, value);
  return res;
};

export const getQualificationsCollection = async (): Promise<QuerySnapshot<DocumentData> | false> => {
  const qualificationsRef = getQualificationsRef();
  if (!qualificationsRef) return false;
  const docs = await getDocs(qualificationsRef);
  return docs;
};

export const getShiftsCollection = async (): Promise<QuerySnapshot<DocumentData> | false> => {
  const shiftsRef = getShiftsRef();
  if (!shiftsRef) return false;
  const docs = await getDocs(shiftsRef);
  return docs;
};

export const getTextsCollection = async (): Promise<QuerySnapshot<DocumentData> | false> => {
  const textRef = getTextsRef();
  if (!textRef) return false;
  const docs = await getDocs(textRef);
  return docs;
};
export const getDepartmentsCollection = async (): Promise<QuerySnapshot<DocumentData> | false> => {
  const departmentsRef = getDepartmentsRef();
  if (!departmentsRef) return false;
  const docs = await getDocs(departmentsRef);
  return docs;
};

export const updateQualification = async (id: string, value: Partial<iQualification>): Promise<void> => {
  const qualificationRef = getQualificationRef(id);
  if (!qualificationRef) return;
  const res = await updateDoc(qualificationRef, value);
  return res;
};

export const updateDepartment = async (id: string, value: Partial<iDepartment>): Promise<void> => {
  const departmentRef = getDepartmentRef(id);
  if (!departmentRef) return;
  const res = await updateDoc(departmentRef, value);
  return res;
};

export const addOffer = async (value: Partial<iOffer>): Promise<string | false> => {
  const offersRef = getOffersRef();
  if (!offersRef) return false;
  const res = await addDoc(offersRef, value);
  return res.id;
};

export const addQualification = async (value: Partial<iQualification>): Promise<string | false> => {
  const qualificationsRef = getQualificationsRef();
  if (!qualificationsRef) return false;
  const res = await addDoc(qualificationsRef, value);
  return res.id;
};

export const getTextById = async (id: string): Promise<DocumentSnapshot<DocumentData> | false> => {
  const textRef = getTextRef(id);
  if (!textRef) return false;
  const res = await getDoc(textRef);
  return res;
};

export const debugDeletePoolShiftIdFromShift = async (id: string): Promise<void> => {
  const shift = await getShiftRef(id);
  if (!shift) return;
  await updateDoc(shift, { poolShiftId: deleteField() });
};

export const setTextById = async (id: string, value: { value: string | string[] }): Promise<void> => {
  const textsRef = getTextRef(id);
  if (!textsRef) return;

  // Note: Texts in db is stored with a value: tag, therefor value in value.. 😞
  const res = await setDoc(textsRef, value);
  return res;
};

export const updateShiftById = async (id: string, value: Partial<iShift>): Promise<void> => {
  const docRef = getShiftRef(id);
  if (!docRef) return;
  const res = updateDoc(docRef, { ...value });
  // Update all departments
  const departments = await getDepartmentsCollection();
  if (!departments) return;
  departments.forEach((department) => {
    const d = department.data() as iDepartment;
    d.id = department.id;
    d.shifts.forEach((s) => {
      if (s.id === id) {
        const cDep = d;
        cDep.shifts = cDep.shifts.map((sn) => (sn.id === id ? { ...sn, ...value } : sn));
        updateDepartment(cDep.id, cDep);
      }
    });
  });
  return res;
};

export const updatePoolShiftById = async (id: string, value: Partial<iPoolShift>): Promise<void> => {
  const docRef = getPoolShiftRef(id);
  if (!docRef) return;
  const res = updateDoc(docRef, { ...value });
  return res;
};

export const addShift = async (value: Partial<iShift>): Promise<DocumentReference<DocumentData> | false> => {
  const shiftsRef = getShiftsRef();
  if (!shiftsRef) return false;
  const res = addDoc(shiftsRef, value);
  return res;
};

export const addPoolShift = async (value: Partial<iPoolShift>): Promise<DocumentReference<DocumentData> | false> => {
  const shiftsRef = getPoolShiftsRef();
  if (!shiftsRef) return false;
  const res = addDoc(shiftsRef, value);
  return res;
};

export const addDepartment = async (value: Partial<iDepartment>): Promise<DocumentReference<DocumentData> | false> => {
  const departmentsRef = getDepartmentsRef();
  if (!departmentsRef) return false;
  const res = addDoc(departmentsRef, value);
  return res;
};

export const updateDepartmentById = async (id: string, value: Partial<iDepartment>): Promise<void> => {
  const docRef = getDepartmentRef(id);
  if (!docRef) return;
  const res = updateDoc(docRef, { ...value });
  return res;
};

export const addUserCapability = async (uid: string, capability: eUserCapabilities): Promise<void> => {
  const docRef = getAUserRef(uid);
  if (!docRef) return;
  const data = await getData(docRef);
  if (!data) return;
  const capabilities: eUserCapabilities[] = data.capabilities || [];
  if (capabilities.includes(capability)) return;
  capabilities.push(capability);
  await updateDoc(docRef, { capabilities });
};

export const removeUserCapability = async (uid: string, capability: eUserCapabilities): Promise<void> => {
  const docRef = getAUserRef(uid);
  if (!docRef) return;
  const data = await getData(docRef);
  if (!data) return;
  const capabilities: eUserCapabilities[] = data.capabilities || [];
  if (!capabilities.includes(capability)) return;
  capabilities.splice(capabilities.indexOf(capability), 1);
  await updateDoc(docRef, { capabilities });
};

export const removeAllFuturePoolItemsForUser = async (uid: string): Promise<void> => {
  const pis = getPoolItemsRef();
  if (!pis) return;
  const q = query(pis, where('userId', '==', uid), where('whenString', '>=', moment().format('YYYYMMDD')));
  const docs = await getDocs(q);
  for (const poolDoc of docs.docs) {
    // eslint-disable-next-line no-await-in-loop
    await deleteDoc(poolDoc.ref);
  }
};

export const removePoolUserSettingsForUser = async (uid: string): Promise<void> => {
  const docRef = getAUserRef(uid);
  if (!docRef) return;
  await updateDoc(docRef, { poolUserDetails: deleteField() });
};

export const getPoolItemRef = (id: string): DocumentReference<DocumentData> | false => {
  const customerId = checkCustomerId();
  const tenantId = checkTenantId();
  if (!tenantId || !customerId) return false;
  const poolItem = doc(db, 'customers', customerId, 'houses', tenantId, 'poolItems', id);
  return poolItem;
};

export const updatePoolItem = async (id: string, value: AllFieldValues<Partial<iPoolItem>>): Promise<void> => {
  const docRef = getPoolItemRef(id);
  if (!docRef) return;
  const res = updateDoc(docRef, { ...value });
  return res;
};

export const deletePoolItem = async (id: string): Promise<void> => {
  const docRef = getPoolItemRef(id);
  if (!docRef) return;
  const res = deleteDoc(docRef);
  return res;
};

export const addPoolItem = async (value: Partial<iPoolItem>): Promise<DocumentReference<DocumentData> | false> => {
  const poolItemsRef = getPoolItemsRef();
  if (!poolItemsRef) return false;
  const res = addDoc(poolItemsRef, value);
  return res;
};

export const setApiKeyInSecret = async (key: string): Promise<void> => {
  const customerId = checkCustomerId();
  const tenantId = checkTenantId();
  if (!tenantId || !customerId) return;
  const docRef = doc(db, 'customers', customerId, 'houses', tenantId, 'secret', 'apiKey');
  await setDoc(docRef, { key, date: Timestamp.now() });
};

export const getAllLeasingCompanies = async () => {
  const customerId = checkCustomerId();
  const tenantId = checkTenantId();
  if (!tenantId || !customerId) return false;
  const col = collection(db, 'leasingCompanies', 'public', 'allCompanies');
  const res = await getDocs(col);
  return res.docs.map((d) => ({ id: d.id, ...d.data() }));
};

export const getLeasingOffersToCustomer = (start: string, end: string): Query<DocumentData> | false => {
  const customerId = checkCustomerId();
  const tenantId = checkTenantId();
  if (!tenantId || !customerId) return false;
  const offers = collection(db, 'leasingEmployeeOffers');
  const q = query(
    offers,
    where('offerToIds', 'array-contains', tenantId),
    where('acceptedById', 'in', [tenantId, '']),
    where('whenString', '>=', start),
    where('whenString', '<=', end),
    orderBy('whenString', 'asc'),
  );
  return q;
};

export const getLeasingOffersToCustomerById = (id: string): DocumentReference<DocumentData> | false => {
  const customerId = checkCustomerId();
  const tenantId = checkTenantId();
  if (!tenantId || !customerId) return false;
  const offer = doc(db, 'leasingEmployeeOffers', id);
  return offer;
};

export const acceptLeasingOfferToCustomer = async (
  offerId: string,
  acceptedByName: string,
  acceptedShiftShort: string,
  acceptedShiftCustomer: iShift | false,
  acceptedQualification: iLeasingQualificationBase,
  offerTo: { customerId: string; houseId: string; name: string; status: eLeasingEmployeeOfferStatus }[],
  department: iDepartment,
  acceptedByHouseAddressLine1: string,
  acceptedByHouseAddressLine2: string,
  acceptedByHousePostalCode: string,
  acceptedByHouseCity: string,
): Promise<void> => {
  const tenantId = checkTenantId();
  if (!tenantId) return;
  const docRef = doc(db, 'leasingEmployeeOffers', offerId);
  await updateDoc(docRef, {
    status: eLeasingEmployeeOfferStatus.Accepted,
    acceptedById: tenantId,
    acceptedByName,
    acceptedAt: Timestamp.now(),
    acceptedShiftShort,
    acceptedShiftCustomer,
    acceptedQualification,
    offerTo,
    department,
    acceptedByHouseAddressLine1,
    acceptedByHouseAddressLine2,
    acceptedByHousePostalCode,
    acceptedByHouseCity,
  });
  // Cancel all other offers the employee has on this day
};

export const rejectLeasingOfferToCustomer = async (
  offerId: string,
  offerTo: { customerId: string; houseId: string; name: string; status: eLeasingEmployeeOfferStatus }[],
  rejectedBy: { customerId: string; houseId: string; name: string }[],
): Promise<void> => {
  const tenantId = checkTenantId();
  if (!tenantId) return;
  const allRejected = offerTo.every((offer) => offer.status === eLeasingEmployeeOfferStatus.Rejected);
  const docRef = doc(db, 'leasingEmployeeOffers', offerId);
  await updateDoc(docRef, {
    offerTo,
    rejectedByIds: arrayUnion(tenantId),
    status: allRejected ? eLeasingEmployeeOfferStatus.Rejected : eLeasingEmployeeOfferStatus.New,
    rejectedBy,
  });
};

export const getLeasingContactInformationById = async (id: string): Promise<iContactInformation[] | false> => {
  const customerId = checkCustomerId();
  const tenantId = checkTenantId();
  if (!tenantId || !customerId) return false;
  const collectionRef = collection(db, 'leasingCompanies', id, 'contactInformation');
  if (!collectionRef) return false;
  const snapshot = await getDocs(collectionRef);
  return snapshot.docs.map((d) => ({ id: d.id, ...d.data() } as iContactInformation));
};

export const getAllCustomerHouses = async () => {
  const customerId = checkCustomerId();
  const tenantId = checkTenantId();
  if (!tenantId || !customerId) return false;
  const partnersCollection = collection(db, 'customers', customerId, 'houses');
  const res = await getDocs(partnersCollection);
  return res.docs.map((d) => ({ id: d.id, ...d.data() }));
};

export const addPoolItemRequest = async (
  value: Partial<iPoolItemRequest>,
): Promise<DocumentReference<DocumentData> | false> => {
  const poolItemRequestsRef = getPoolItemRequestsRef();
  if (!poolItemRequestsRef) return false;
  const res = addDoc(poolItemRequestsRef, value);
  return res;
};

export const getReleaseNotesRef = (): CollectionReference<DocumentData, DocumentData> => {
  const releaseNotes = collection(db, 'releaseNotes');
  return releaseNotes;
};
export const getReleaseNotesStateRef = (releaseNoteId: string) => {
  const customerId = checkCustomerId();
  const uid = checkUserId();
  if (!customerId) return false;
  if (!uid) return false;
  const customerNews = doc(db, 'releaseNotes', releaseNoteId, 'state', uid);
  return customerNews;
};
