/* eslint-disable max-lines */
import { createTheme } from '@mui/material';
import { deDE as coreDeDE, enUS as coreEnUS } from '@mui/material/locale';
import { deDE, enUS } from '@mui/x-data-grid/locales';
import { deDE as pickersDeDE, enUS as pickersEnUS } from '@mui/x-date-pickers/locales';
import { theme as sharedTheme } from 'shared';

declare module '@mui/material/styles' {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Theme {
    product: {
      scrollbarTrack: string;
      scrollbarThumb: string;

      theme: {
        primaryColor: {
          primary: string;
          primaryLight: string;
          primaryDark: string;
          primaryContrastText: string;
        };
        secondaryColor: {
          secondary: string;
          secondaryLight: string;
          secondaryDark: string;
          secondaryContrastText: string;
        };
        text: {
          primary: string;
          secondary: string;
        };
        errorColor: {
          error: string;
        };
        muiCardHeader: {
          textColor: string;
          backgroundColor: string;
        };
        muiDialogTitle: {
          textColor: string;
          backgroundColor: string;
        };
      };
      headerBar: {
        backgroundColor: {
          hastItemsOnDay: string;
          noItemsOnDay: string;
          default: string;
          employeeName: string;
        };
      };
      canvas: {
        backgroundColor: string;
      };
      calendar: {
        headers: {
          today: string;
          other: string;
        };
        pool: {
          accepted: string;
          rejected: string;
          offered: string;
          new: string;
        };
        darkPool: {
          accepted: string;
          rejected: string;
          // Change next line to red to highlight the icon for the pool admin that it is pending the users feedback
          offered: string;
          new: string;
        };
        poolNotAdmin: {
          accepted: string;
          rejected: string;
          offered: string;
          new: string;
        };
        darkPoolNotAdmin: {
          accepted: string;
          rejected: string;
          offered: string;
          new: string;
        };
        leasing: {
          dark: {
            new: string;
            completed: string;
            cancelled: string;
            hasOffer: string;
            accepted: string;
          };
          light: {
            new: string;
            completed: string;
            cancelled: string;
            hasOffer: string;
            accepted: string;
          };
        };
        leasingToCustomer: {
          dark: {
            new: string;
            accepted: string;
            other: string;
          };
          light: {
            new: string;
            accepted: string;
            other: string;
          };
        };
        offer: {
          dark: {
            cancelled: string;
            rejected: string;
            isAssigned: string;
            accepted: string;
            other: string;
          };
          light: {
            cancelled: string;
            rejected: string;
            isAssigned: string;
            accepted: string;
            other: string;
          };
        };
      };
      overview: {
        available: string;
        notAvailable: {
          off: string;
          desiredOff: string;
          azk: string;
          sickWithAU: string;
          sickWithoutAU: string;
          sickChild: string;
          paidVacation: string;
          unpaidVacation: string;
          shiftNoReported: string;
          guarantee: string;
          shiftDeclined: string;
          parentalLeave: string;
          maternityProtection: string;
          employmentBan: string;
        };
        multiDayIsOption: string;
        alsoAvailable: string;
        openOffer: string;
        openOfferTransparent: string;
        offered: string;
        assigned: string;
        rejected: string;
        cancelledByLeasing: string;
        offerToCustomer: {
          borderColor: string;
          iconColor: string;
        };
      };
      sideBarLeft: {
        activeItem: string;
        inactiveItem: string;
        activeItemBackground: string;
      };
      header: {
        fontColor: string;
      };
      employeeBar: {
        hasConflict: string;
        iconColor: string;
      };
    };
    custom: {
      primary: string;
      secondary: string;
      accent: string;
      accentTransparent: string;
      primaryTransparent: string;
      primaryTransparentLight: string;
      primaryDark: string;
      primaryLight: string;
      primaryText: string;
      primaryTextSoft: string;
      primaryTextRgba: (opacity: number) => string;
      secondaryText: string;
      secondaryTextSoft: string;
      secondaryTextRgba: (opacity: number) => string;
      backgroundRgba: (opacity: number) => string;
      accentRgba: (opacity: number) => string;
      primaryRgba: (opacity: number) => string;
      secondaryRgba: (opacity: number) => string;
      surface: string;
      surfaceDark: string;
      surfaceRgba: (opacity: number) => string;
      background: string;
      secondaryContainer: string;
      grey: {
        dark: string;
        normal: string;
        normalRgba: (opacity: number) => string;
        light: string;
        lightRgba: (opacity: number) => string;
        yellowShadow: string;
      };
      disabled: string;
      white: string;
      dropShadow: string;
      border: {
        lightest: string;
        lighter: string;
        light: string;
        normal: string;
        dark: string;
        rgba(opacity: number): string;
      };
      lights: {
        green: {
          light: string;
          normal: string;
          dark: string;
          transparent(opacity: number): string;
        };
        yellow: {
          light: string;
          normal: string;
          dark: string;
          transparent(opacity: number): string;
        };
        red: {
          light: string;
          normal: string;
          dark: string;
          transparent(opacity: number): string;
        };
        neutral: {
          light: string;
          lighter: string;
          lightest: string;
          normal: string;
          dark: string;
          transparent(opacity: number): string;
        };
        blue: {
          light: string;
          normal: string;
          dark: string;
          transparent(opacity: number): string;
        };
      };
      black: string;
      whiteOverlay: string;
      primaryError: string;
      blackFontLight: string;
      blackFontNormal: string;
      blackFontDark: string;
      blackTransparent: string;
    };
  }
  // allow configuration using `createTheme`
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface ThemeOptions {
    product?: {
      scrollbarTrack?: string;
      scrollbarThumb?: string;
      theme?: {
        primaryColor?: {
          primary?: string;
          primaryLight?: string;
          primaryDark?: string;
          primaryContrastText?: string;
          primaryRgba: (opacity: number) => string;
        };
        secondaryColor?: {
          secondary?: string;
          secondaryLight?: string;
          secondaryDark?: string;
          secondaryContrastText?: string;
          secondaryRgba: (opacity: number) => string;
        };
        text?: {
          primary?: string;
          secondary?: string;
        };
        errorColor?: {
          error?: string;
        };
        muiCardHeader?: {
          textColor?: string;
          backgroundColor?: string;
        };
        muiDialogTitle?: {
          textColor?: string;
          backgroundColor?: string;
        };
      };
      headerBar?: {
        backgroundColor?: {
          hastItemsOnDay?: string;
          noItemsOnDay?: string;
          default?: string;
          employeeName?: string;
        };
      };
      canvas?: {
        backgroundColor?: string;
      };
      calendar?: {
        headers?: {
          today?: string;
          other?: string;
        };
        pool?: {
          accepted?: string;
          rejected?: string;
          offered?: string;
          new?: string;
        };
        darkPool?: {
          accepted?: string;
          rejected?: string;
          // Change next line to red to highlight the icon for the pool admin that it is pending the users feedback
          offered?: string;
          new?: string;
        };
        poolNotAdmin?: {
          accepted?: string;
          rejected?: string;
          offered?: string;
          new?: string;
        };
        darkPoolNotAdmin?: {
          accepted?: string;
          rejected?: string;
          offered?: string;
          new?: string;
        };
        leasing?: {
          dark?: {
            new?: string;
            completed?: string;
            cancelled?: string;
            hasOffer?: string;
            accepted?: string;
          };
          light?: {
            new?: string;
            completed?: string;
            cancelled?: string;
            hasOffer?: string;
            accepted?: string;
          };
        };
        leasingToCustomer?: {
          dark?: {
            new?: string;
            accepted?: string;
            other?: string;
          };
          light?: {
            new?: string;
            accepted?: string;
            other?: string;
          };
        };
        offer?: {
          dark?: {
            cancelled?: string;
            rejected?: string;
            isAssigned?: string;
            accepted?: string;
            other?: string;
          };
          light?: {
            cancelled?: string;
            rejected?: string;
            isAssigned?: string;
            accepted?: string;
            other?: string;
          };
        };
      };
      overview?: {
        available?: string;
        notAvailable?: {
          off?: string;
          desiredOff?: string;
          azk?: string;
          sickWithAU?: string;
          sickWithoutAU?: string;
          sickChild?: string;
          paidVacation?: string;
          unpaidVacation?: string;
          shiftNoReported?: string;
          guarantee?: string;
          shiftDeclined?: string;
          parentalLeave?: string;
          maternityProtection?: string;
          employmentBan?: string;
        };
        multiDayIsOption?: string;
        alsoAvailable?: string;
        openOffer?: string;
        openOfferTransparent?: string;
        offered?: string;
        assigned?: string;
        rejected?: string;
        cancelledByLeasing?: string;
        offerToCustomer?: {
          borderColor?: string;
          iconColor?: string;
        };
      };
      sideBarLeft?: {
        activeItem?: string;
        inactiveItem?: string;
        activeItemBackground?: string;
      };
      header?: {
        fontColor?: string;
      };
      employeeBar?: {
        hasConflict?: string;
        iconColor?: string;
      };
    };
    custom?: {
      primary?: string;
      secondary?: string;
      accent?: string;
      accentTransparent?: string;
      primaryTransparent?: string;
      primaryTransparentLight?: string;
      primaryDark?: string;
      primaryLight?: string;
      primaryText?: string;
      primaryTextSoft?: string;
      primaryTextRgba?: (opacity?: number) => string;
      secondaryText?: string;
      secondaryTextSoft?: string;
      secondaryTextRgba?: (opacity?: number) => string;
      backgroundRgba?: (opacity?: number) => string;
      accentRgba?: (opacity?: number) => string;
      primaryRgba?: (opacity?: number) => string;
      secondaryRgba?: (opacity?: number) => string;
      surface?: string;
      surfaceDark?: string;
      surfaceRgba?: (opacity?: number) => string;
      background?: string;
      secondaryContainer?: string;
      grey?: {
        dark: string;
        normal: string;
        normalRgba: (opacity: number) => string;
        light: string;
        lightRgba: (opacity: number) => string;
        yellowShadow: string;
      };
      disabled?: string;
      white?: string;
      dropShadow?: string;
      border?: {
        lightest: string;
        lighter: string;
        light: string;
        normal: string;
        dark: string;
        rgba(opacity: number): string;
      };
      lights?: {
        green: {
          light: string;
          normal: string;
          dark: string;
          transparent(opacity: number): string;
        };
        yellow: {
          light: string;
          normal: string;
          dark: string;
          transparent(opacity: number): string;
        };
        red: {
          light: string;
          normal: string;
          dark: string;
          transparent(opacity: number): string;
        };
        neutral: {
          light: string;
          lighter: string;
          lightest: string;
          normal: string;
          dark: string;
          transparent(opacity: number): string;
        };
        blue: {
          light: string;
          normal: string;
          dark: string;
          transparent(opacity: number): string;
        };
      };
      black?: string;
      whiteOverlay?: string;
      primaryError?: string;
      blackFontLight?: string;
      blackFontNormal?: string;
      blackFontDark?: string;
      blackTransparent?: string;
    };
  }
}

const theme = createTheme();

// eslint-disable-next-line import/prefer-default-export
export const leasingTheme = (lng: 'de' | 'en') => {
  return createTheme(
    {
      product: {
        ...sharedTheme.products.leasing,
      },
      custom: {
        primary: sharedTheme.colors.products.leasing.primary,
        secondary: sharedTheme.colors.products.leasing.secondary,
        accent: sharedTheme.colors.products.leasing.accent,
        accentTransparent: sharedTheme.colors.products.leasing.accentRgba(0.3),
        primaryTransparent: sharedTheme.colors.products.leasing.primaryRgba(0.3),
        primaryTransparentLight: sharedTheme.colors.products.leasing.primaryRgba(0.2),
        primaryDark: sharedTheme.colors.products.leasing.primaryDark,
        primaryLight: sharedTheme.colors.products.leasing.primaryLight,
        primaryText: sharedTheme.colors.products.leasing.secondaryFontColor,
        primaryTextSoft: sharedTheme.colors.products.leasing.secondaryFontColorRgba(0.8),
        primaryTextRgba: (opacity: number | undefined) =>
          sharedTheme.colors.products.leasing.secondaryFontColorRgba(opacity || 1),
        secondaryText: sharedTheme.colors.products.leasing.primaryFontColor,
        secondaryTextSoft: sharedTheme.colors.products.leasing.primaryFontColorRgba(0.8),
        secondaryTextRgba: (opacity: number | undefined) =>
          sharedTheme.colors.products.leasing.primaryFontColorRgba(opacity || 1),
        backgroundRgba: (opacity: number | undefined) => sharedTheme.colors.backgroundRgba(opacity || 1),
        accentRgba: (opacity: number | undefined) => sharedTheme.colors.products.leasing.accentRgba(opacity || 1),
        primaryRgba: (opacity: number | undefined) => sharedTheme.colors.products.leasing.primaryRgba(opacity || 1),
        secondaryRgba: (opacity: number | undefined) => sharedTheme.colors.products.leasing.secondaryRgba(opacity || 1),
        surface: sharedTheme.colors.surface,
        // eslint-disable-next-line max-lines
        surfaceDark: sharedTheme.colors.surfaceDark,
        surfaceRgba: (opacity: number | undefined) => sharedTheme.colors.surfaceRgba(opacity || 1),
        background: sharedTheme.colors.white,
        secondaryContainer: 'transparent',
        grey: sharedTheme.colors.products.grey,
        disabled: sharedTheme.colors.disabled,
        white: sharedTheme.colors.white,
        dropShadow: sharedTheme.colors.dropShadow,
        border: sharedTheme.colors.border,
        lights: sharedTheme.colors.lights,
        black: sharedTheme.colors.black,
        whiteOverlay: sharedTheme.colors.whiteOverlayRgba(0.6),
        primaryError: sharedTheme.colors.products.error.primary,
        blackFontLight: sharedTheme.colors.blackFont.light,
        blackFontNormal: sharedTheme.colors.blackFont.normal,
        blackFontDark: sharedTheme.colors.blackFont.dark,
        blackTransparent: sharedTheme.colors.blackRgba(0.1),
      },
      palette: {
        primary: {
          main: sharedTheme.products.leasing.theme.primaryColor.primary,
          light: sharedTheme.products.leasing.theme.primaryColor.primaryLight,
          dark: sharedTheme.products.leasing.theme.primaryColor.primaryDark,
          contrastText: sharedTheme.products.leasing.theme.primaryColor.primaryContrastText,
        },
        secondary: {
          main: sharedTheme.products.leasing.theme.secondaryColor.secondary,
          light: sharedTheme.products.leasing.theme.secondaryColor.secondaryLight,
          dark: sharedTheme.products.leasing.theme.secondaryColor.secondaryDark,
          contrastText: sharedTheme.products.leasing.theme.secondaryColor.secondaryContrastText,
        },
        text: {
          primary: sharedTheme.products.leasing.theme.text.primary,
          secondary: sharedTheme.products.leasing.theme.text.secondary,
        },
        error: {
          main: sharedTheme.products.leasing.theme.errorColor.error,
        },
      },
      typography: {
        fontFamily:
          'Roboto, hk-grotesk, -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif',
        body1: { lineHeight: 1.7 },
        h1: { fontSize: '2.5rem', marginBottom: theme.spacing(1) },
        h2: { fontSize: '2rem', marginBottom: theme.spacing(1) },
        h3: { fontSize: '1.75rem', marginBottom: theme.spacing(1) },
        h4: { fontSize: '1.5rem', marginBottom: theme.spacing(1) },
        h5: { fontSize: '1.25rem', marginBottom: theme.spacing(1) },
        h6: { fontSize: '1rem', marginBottom: theme.spacing(1) },
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: 'none',
            },
          },
        },
        MuiAccordion: {
          styleOverrides: {
            root: {
              marginTop: theme.spacing(2),
              marginBottom: theme.spacing(2),
              ':before': {
                display: 'none',
              },
            },
          },
        },
        MuiTextField: {
          styleOverrides: {
            root: { marginBottom: theme.spacing(1) },
          },
        },
        MuiCard: {
          defaultProps: {
            elevation: 5,
          },
          styleOverrides: {
            root: {
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'unset',
            },
          },
        },
        MuiCardHeader: {
          styleOverrides: {
            root: {
              padding: theme.spacing(1),
              overflow: 'unset',
              textAlign: 'center',
              borderRadius: theme.shape.borderRadius,
              color: theme.palette.getContrastText(sharedTheme.products.leasing.theme.muiCardHeader.textColor),
              background: sharedTheme.products.leasing.theme.muiCardHeader.backgroundColor,
            },
          },
        },
        MuiCardActionArea: {
          styleOverrides: {
            root: {
              padding: theme.spacing(1),
              flexDirection: 'row',
              justifyContent: 'space-between',
            },
          },
        },
        MuiCardContent: {
          styleOverrides: {
            root: {
              marginTop: theme.spacing(1),
              padding: theme.spacing(2),
              flex: '1 1 auto',
              position: 'relative',
            },
          },
        },
        MuiDrawer: {
          styleOverrides: {
            paper: {
              backgroundColor: sharedTheme.products.leasing.canvas.backgroundColor,
              overflow: 'hidden',
              border: 'none',
            },
          },
        },
        MuiDialogTitle: {
          styleOverrides: {
            root: {
              padding: theme.spacing(2),
              overflow: 'unset',
              textAlign: 'center',
              color: sharedTheme.products.leasing.theme.muiDialogTitle.textColor,
              background: sharedTheme.products.leasing.theme.muiDialogTitle.backgroundColor,
            },
          },
        },
        MuiDialogContent: {
          styleOverrides: {
            root: {
              marginTop: theme.spacing(1),
              padding: theme.spacing(2),
              flex: '1 1 auto',
              position: 'relative',
            },
          },
        },
      },
    },
    lng === 'de' ? deDE : enUS,
    lng === 'de' ? pickersDeDE : pickersEnUS,
    lng === 'de' ? coreDeDE : coreEnUS,
  );
};
