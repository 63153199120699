import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { iDepartment } from 'datamodel';

const initialState: iDepartment[] = [];

export const departmentsSlice = createSlice({
  name: 'departments',
  initialState,
  reducers: {
    setDepartmentsValue: (state, action: PayloadAction<iDepartment[]>) =>
      action.payload.sort((a, b) => a.name.localeCompare(b.name)),
  },
});

// Action creators are generated for each case reducer function
export const { setDepartmentsValue } = departmentsSlice.actions;

export default departmentsSlice.reducer;
